<template>
  <div class="columns is-centered is-marginless">
    <div
      class="column is-6"
    >
      <div class="column is-narrow">
        <h1 class="is-size-4 has-text-weight-medium">
          {{ isEdit ? 'Edit' : 'Create'}} Appointment
        </h1>
      </div>
      <div class="column is-narrow">
        <form @submit.prevent="create" class="mb-5">
          <b-field
            :type="error.doctorId ? 'is-danger' : ''"
            :message="error.doctorId">
            <b-autocomplete
              v-model="searchDoctor"
              :data="filteredDataDoctor"
              icon="magnify"
              field="name"
              clearable
              open-on-focus
              placeholder="Search doctor"
              @select="option => {
                appointment.doctorId = option.userId
                appointment.doctorName = option.name
                appointment.doctorPhone = option.mobileNumber
              }">
              <template #empty>No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field
            :type="error.patientId ? 'is-danger' : ''"
            :message="error.patientId">
            <b-autocomplete
              v-model="searchPatient"
              :data="filteredDataPatient"
              icon="magnify"
              field="name"
              clearable
              open-on-focus
              placeholder="Search patient"
              custom-class="custum-input"
              @select="option => {
                appointment.patientId = option.userId
                appointment.patientName = option.name
                appointment.patientPhone = option.mobileNumber
              }"
              :disabled="isEdit">
              <template #empty>No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field
            custom-class=""
            :type="error.startAt ? 'is-danger' : ''"
            :message="error.startAt"
          >
            <b-datetimepicker
              placeholder="Select date"
              icon="calendar-today"
              custom-class="custum-input"
              v-model="appointment.startAt"
              :min-datetime="minDatetime"
              >
            </b-datetimepicker>
          </b-field>
          <b-field
            custom-class=""
            :type="error.patientDesc ? 'is-danger' : ''"
            :message="error.patientDesc"
          >
            <b-input
              placeholder="Description"
              custom-class="custum-input"
              v-model="appointment.patientDesc"
              type="textarea"
              >
            </b-input>
          </b-field>
          <b-field v-if="!isEdit">
            <b-checkbox v-model="appointment.markAsPaid">
              Mark as paid
            </b-checkbox>
        </b-field>
        </form>
        <div class="columns is-centered">
          <div class="column">
            <b-button
              expanded
              @click="$router.go(-1)"
              class="has-text-weight-bold has-text-primary"
            >
              Go Back
            </b-button>
          </div>
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="create"
              class="has-text-weight-bold"
            >
              {{ isEdit ? 'Edit' : 'Create'}}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { validationAppointmentAdmin } from '@/services/validation';
import { CreateAppointmentAdmin, GetAppointment, UpdateAppointmentAdmin } from '@/api/appointment';
import { GetUserDoctor, GetUserPatient } from '@/api/user';
import { ADMIN } from '@/config/user';

export default {
  metaInfo() {
    return {
      title: this.isEdit ? 'Edit Appointment (Admin)' : 'Create Appointment (Admin)',
    };
  },
  data() {
    return {
      appointment: {
        startAt: new Date(),
        patientDesc: '',
        patientId: '',
        patientName: '',
        patientPhone: '',
        doctorId: '',
        doctorName: '',
        doctorPhone: '',
        markAsPaid: false,
      },
      error: {
        startAt: '',
        patientDesc: '',
        patientId: '',
        patientName: '',
        patientPhone: '',
        doctorId: '',
        doctorName: '',
        doctorPhone: '',
      },
      minDatetime: new Date(),
      searchDoctor: '',
      doctors: [],
      searchPatient: '',
      patients: [],
    };
  },
  computed: {
    ...mapGetters({
      profilUser: 'profil',
    }),
    filteredDataDoctor() {
      return this.doctors
        .filter((el) => {
          return el.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchDoctor.toLowerCase()) >= 0;
        });
    },
    filteredDataPatient() {
      return this.patients
        .filter((el) => {
          return el.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchPatient.toLowerCase()) >= 0;
        });
    },
    isEdit() {
      return this.$route.name === 'EditAppointmentAdmin';
    },
  },
  methods: {
    ...mapMutations({}),
    async create() {
      if (!validationAppointmentAdmin(this.appointment, this.error, 2000)) return '';
      const loadingComponent = this.$buefy.loading.open();
      try {
        if (this.isEdit) {
          await UpdateAppointmentAdmin(this.$route.params.appointmentId, this.appointment);
        } else await CreateAppointmentAdmin(this.appointment);
        this.$router.push({ name: 'Appointment' });
        loadingComponent.close();
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
  },
  async mounted() {
    if (this.isEdit) {
      const loadingComponent = this.$buefy.loading.open();
      const appointment = (await GetAppointment(this.$route.params.appointmentId)).data.appointment;
      this.appointment = {
        appointmentId: appointment.appointmentId,
        startAt: new Date(appointment.startAt),
        patientDesc: appointment.patientDesc,
        patientId: appointment.patientId,
        patientName: appointment.patientName,
        patientPhone: appointment.patientPhone,
        doctorId: appointment.doctorId,
        doctorName: appointment.doctorName,
        doctorPhone: appointment.doctorPhone,
        status: appointment.status,
        isPay: appointment.isPay,
        doctorDesc: appointment.doctorDesc,
      };
      this.searchDoctor = appointment.doctorName;
      this.searchPatient = appointment.patientName;
      loadingComponent.close();
    }
    if (this.profilUser === ADMIN) {
      const loadingComponent = this.$buefy.loading.open();
      const resDoc = (await GetUserDoctor()).data;
      const resPatient = (await GetUserPatient()).data;
      this.doctors = resDoc.users
        .map(el => ({
          ...el,
          name: `${el.firstName} ${el.lastName}`,
        }));
      this.patients = resPatient.users
        .map(el => ({
          ...el,
          name: `${el.firstName} ${el.lastName}`,
        }));
      loadingComponent.close();
    }
  },
};
</script>
